<template>
  <v-app id="customersTable">
    <div>
      <div class="text-center mt-3" v-if="isLoading">
        <b-spinner />
      </div>
      <div v-else>
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          placeholder="Type to Search"
          class="mb-3"
          test-id="cm001-search-input"
        ></b-form-input>
        <v-data-table
          hover
          :loading="isLoading"
          :headers="headers"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="customerData"
          :search="filter"
          :footer-props="{
            'items-per-page-options': [20, 40, 60, -1],
          }"
          :items-per-page="20"
          test-id="cm001-data-table"
        >
          <template #cell(fullName)="data">{{ data.item.fullName }}</template>
          <template #cell(code)="data">{{ data.item.code }}</template>
          <template #cell(email)="data">{{ data.item.email }}</template>
        </v-data-table>
      </div>
    </div>
  </v-app>
</template>

<script>
import { BFormInput, BSpinner } from "bootstrap-vue";
import { getCustomers } from "@/api/CustomerApi";

export default {
  components: {
    "b-spinner": BSpinner,
    "b-form-input": BFormInput,
  },
  name: "customersTable",
  data() {
    return {
      filter: null,
      customerData: [],
      isConfirmLoading: false,
      headers: [
        {
          value: "fullName",
          text: "Full Name",
          sortable: true,
        },
        {
          value: "code",
          text: "Customer code",
          sortable: true,
        },
        {
          value: "email",
          text: "E-Mail",
          sortable: true,
        },
      ],
      sortBy: "fullName",
      sortDesc: false,
      isLoading: true,
    };
  },
  methods: {
    fetchCustomers() {
      this.isLoading = true;
      getCustomers()
        .then((response) => {
          this.customerData = response.data.filter(
            (item) => item.type === "REAL"
          );
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    async fetchData() {
      await this.fetchCustomers();
    },
  },
  beforeMount() {
    const vm = this;
    vm.fetchData();
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
