<template>
  <div class="mr-5 ml-5 mt-4 fontStyle">
    <div>
      <div
        class="d-flex align-items-center p-3 my-3 text-white-50 rounded bg-charger-box"
      >
        <div class="lh-100">
          <h6 class="mb-0 text-white lh-100">Customers</h6>
        </div>
      </div>
      <div class="rounded shadow-sm">
        <CustomersTable />
      </div>
    </div>
  </div>
</template>

<script>
import CustomersTable from "@/components/CustomersTable.vue";
export default {
  name: "CustomersView",
  components: {
    CustomersTable,
  },
};
</script>
<style scoped src="@/assets/css/energia.min.css"></style>
